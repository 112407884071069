import { template as template_1722983e5ac347728e2a2b486207188f } from "@ember/template-compiler";
const WelcomeHeader = template_1722983e5ac347728e2a2b486207188f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
