import { template as template_c6fdcf47a4a8417ca09f6d261017fed8 } from "@ember/template-compiler";
const FKLabel = template_c6fdcf47a4a8417ca09f6d261017fed8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
