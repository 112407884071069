define("discourse/plugins/discourse-solved/discourse/initializers/extend-for-solved-button", ["exports", "@ember/object", "discourse/lib/decorators", "discourse/lib/plugin-api", "discourse/lib/utilities", "discourse/models/topic", "discourse/models/user", "discourse/widgets/post-cooked", "discourse-common/lib/deprecated", "discourse-common/lib/icon-library", "discourse-i18n", "discourse/plugins/discourse-solved/discourse/components/solved-accept-answer-button", "discourse/plugins/discourse-solved/discourse/components/solved-unaccept-answer-button"], function (_exports, _object, _decorators, _pluginApi, _utilities, _topic, _user, _postCooked, _deprecated, _iconLibrary, _discourseI18n, _solvedAcceptAnswerButton, _solvedUnacceptAnswerButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeWithApi(api) {
    customizePostMenu(api);
    api.includePostAttributes("can_accept_answer", "can_unaccept_answer", "accepted_answer", "topic_accepted_answer");
    if (api.addDiscoveryQueryParam) {
      api.addDiscoveryQueryParam("solved", {
        replace: true,
        refreshModel: true
      });
    }
    api.decorateWidget("post-contents:after-cooked", dec => {
      if (dec.attrs.post_number === 1) {
        const postModel = dec.getModel();
        if (postModel) {
          const topic = postModel.topic;
          if (topic.accepted_answer) {
            const hasExcerpt = !!topic.accepted_answer.excerpt;
            const withExcerpt = `
            <aside class='quote accepted-answer' data-post="${topic.get("accepted_answer").post_number}" data-topic="${topic.id}">
              <div class='title'>
                ${topic.acceptedAnswerHtml} <div class="quote-controls"><\/div>
              </div>
              <blockquote>
                ${topic.accepted_answer.excerpt}
              </blockquote>
            </aside>`;
            const withoutExcerpt = `
            <aside class='quote accepted-answer'>
              <div class='title title-only'>
                ${topic.acceptedAnswerHtml}
              </div>
            </aside>`;
            const cooked = new _postCooked.default({
              cooked: hasExcerpt ? withExcerpt : withoutExcerpt
            }, dec);
            return dec.rawHtml(cooked.init());
          }
        }
      }
    });
    api.attachWidgetAction("post", "acceptAnswer", function () {
      (0, _solvedAcceptAnswerButton.acceptAnswer)(this.model, this.appEvents);
    });
    api.attachWidgetAction("post", "unacceptAnswer", function () {
      (0, _solvedUnacceptAnswerButton.unacceptAnswer)(this.model, this.appEvents);
    });
  }
  function customizePostMenu(api) {
    const transformerRegistered = api.registerValueTransformer("post-menu-buttons", _ref => {
      let {
        value: dag,
        context: {
          post,
          firstButtonKey,
          secondLastHiddenButtonKey,
          lastHiddenButtonKey
        }
      } = _ref;
      let solvedButton;
      if (post.can_accept_answer) {
        solvedButton = _solvedAcceptAnswerButton.default;
      } else if (post.accepted_answer) {
        solvedButton = _solvedUnacceptAnswerButton.default;
      }
      solvedButton && dag.add("solved", solvedButton, post.topic_accepted_answer && !post.accepted_answer ? {
        before: lastHiddenButtonKey,
        after: secondLastHiddenButtonKey
      } : {
        before: ["assign",
        // button added by the assign plugin
        firstButtonKey]
      });
    });
    const silencedKey = transformerRegistered && "discourse.post-menu-widget-overrides";
    (0, _deprecated.withSilencedDeprecations)(silencedKey, () => customizeWidgetPostMenu(api));
  }
  function customizeWidgetPostMenu(api) {
    const currentUser = api.getCurrentUser();
    api.addPostMenuButton("solved", attrs => {
      if (attrs.can_accept_answer) {
        const isOp = currentUser?.id === attrs.topicCreatedById;
        return {
          action: "acceptAnswer",
          icon: "far-square-check",
          className: "unaccepted",
          title: "solved.accept_answer",
          label: isOp ? "solved.solution" : null,
          position: attrs.topic_accepted_answer ? "second-last-hidden" : "first"
        };
      } else if (attrs.accepted_answer) {
        if (attrs.can_unaccept_answer) {
          return {
            action: "unacceptAnswer",
            icon: "square-check",
            title: "solved.unaccept_answer",
            className: "accepted fade-out",
            position: "first",
            label: "solved.solution"
          };
        } else {
          return {
            className: "hidden",
            disabled: "true",
            position: "first",
            beforeButton(h) {
              return h("span.accepted-text", {
                title: (0, _discourseI18n.i18n)("solved.accepted_description")
              }, [h("span", (0, _iconLibrary.iconNode)("check")), h("span.accepted-label", (0, _discourseI18n.i18n)("solved.solution"))]);
            }
          };
        }
      }
    });
  }
  var _default = _exports.default = {
    name: "extend-for-solved-button",
    initialize() {
      _topic.default.reopen({
        // keeping this here cause there is complex localization
        acceptedAnswerHtml: (0, _object.computed)("accepted_answer", "id", function () {
          const username = this.get("accepted_answer.username");
          const name = this.get("accepted_answer.name");
          const postNumber = this.get("accepted_answer.post_number");
          if (!username || !postNumber) {
            return "";
          }
          const displayedUser = this.siteSettings.display_name_on_posts && name ? name : (0, _utilities.formatUsername)(username);
          return (0, _discourseI18n.i18n)("solved.accepted_html", {
            icon: (0, _iconLibrary.iconHTML)("square-check", {
              class: "accepted"
            }),
            username_lower: username.toLowerCase(),
            username: displayedUser,
            post_path: `${this.url}/${postNumber}`,
            post_number: postNumber,
            user_path: _user.default.create({
              username
            }).path
          });
        })
      });
      (0, _pluginApi.withPluginApi)("2.0.0", api => {
        (0, _deprecated.withSilencedDeprecations)("discourse.hbr-topic-list-overrides", () => {
          let topicStatusIcons;
          try {
            topicStatusIcons = require("discourse/helpers/topic-status-icons").default;
          } catch {}
          topicStatusIcons?.addObject(["has_accepted_answer", "far-square-check", "solved"]);
          api.modifyClass("raw-view:topic-status", Superclass => class extends Superclass {
            statuses() {
              const results = super.statuses;
              if (this.topic.has_accepted_answer) {
                results.push({
                  openTag: "span",
                  closeTag: "span",
                  title: (0, _discourseI18n.i18n)("topic_statuses.solved.help"),
                  icon: "far-square-check",
                  key: "solved"
                });
              } else if (this.topic.can_have_answer && this.siteSettings.solved_enabled && this.siteSettings.empty_box_on_unsolved) {
                results.push({
                  openTag: "span",
                  closeTag: "span",
                  title: (0, _discourseI18n.i18n)("solved.has_no_accepted_answer"),
                  icon: "far-square"
                });
              }
              return results;
            }
            static #_ = (() => dt7948.n(this.prototype, "statuses", [(0, _decorators.default)("topic.{has_accepted_answer,can_have_answer}")]))();
          });
        });
      });
      (0, _pluginApi.withPluginApi)("1.34.0", initializeWithApi);
      (0, _pluginApi.withPluginApi)("0.8.10", api => {
        api.replaceIcon("notification.solved.accepted_notification", "square-check");
      });
      (0, _pluginApi.withPluginApi)("0.11.0", api => {
        api.addAdvancedSearchOptions({
          statusOptions: [{
            name: (0, _discourseI18n.i18n)("search.advanced.statuses.solved"),
            value: "solved"
          }, {
            name: (0, _discourseI18n.i18n)("search.advanced.statuses.unsolved"),
            value: "unsolved"
          }]
        });
      });
      (0, _pluginApi.withPluginApi)("0.11.7", api => {
        api.addSearchSuggestion("status:solved");
        api.addSearchSuggestion("status:unsolved");
      });
    }
  };
});