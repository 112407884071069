define("discourse/plugins/discourse-99designs/discourse-99designs/templates/connectors/user-card-post-names/external-profile-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.user.external_profile_url}}
    <a
      href={{this.user.external_profile_url}}
      style="text-decoration:underline"
    >show 99designs profile</a>
  {{/if}}
  */
  {
    "id": "zsYqlyPL",
    "block": "[[[41,[30,0,[\"user\",\"external_profile_url\"]],[[[1,\"  \"],[10,3],[15,6,[30,0,[\"user\",\"external_profile_url\"]]],[14,5,\"text-decoration:underline\"],[12],[1,\"show 99designs profile\"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"a\"]]",
    "moduleName": "discourse/plugins/discourse-99designs/discourse-99designs/templates/connectors/user-card-post-names/external-profile-link.hbs",
    "isStrictMode": false
  });
});